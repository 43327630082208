<template>
  <div class="note-page">
    <vuestic-widget>
      <template slot="header">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <span class="title">Release Notes</span>
          <div class="d-flex justify-content-end align-items-center">
            <router-link class="btn btn-primary btn-sm" :to="{name: 'admin.releaseNote.create'}">
              <span>Add New</span>
            </router-link>
          </div>
        </div>
      </template>
      <div>
        <div class="row mt-4">
          <div class="col-md-12">
            <div class="pb-2 mb-3">
              <div class="d-flex flex-row justify-content-end align-items-center right-filter-wrapper">
                <div class="d-flex align-items-center justify-content-end flex-row mr-3">
                  <search-input name="searchInput" v-model="searchFilter" @input="debounceSearch()" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center" v-if="isLoading">
              <span class="atom-spinner-wrapper">
                <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)" />
              </span>
            </div>
            <div class="col-md-12" v-else>
              <datatable v-bind="dataTableOptions" class="le-datatable" />
            </div>
          </div>
        </div>
      </div>
    </vuestic-widget>
    <vuestic-modal :isOpen="isOpenModalDelete" :hideDefaultActions="true" @cancel="closeModalDelete" okText="Delete"
      :cancelShown="false" @ok="handleRemoveNote" okClass="btn btn-danger btn-primary">
      <span slot="title" class="text-danger">Delete Note</span>
      <p v-if="selectedNote">Are you sure you want to delete <strong>"#<span>{{
        selectedNote.id }}</span>"</strong>?</p>
    </vuestic-modal>
  </div>
</template>
<script>
import Action from './Action'
import components from '../../common/tables/comps'
import SearchInput from '../../common/SearchInput'
import debounce from "debounce"

export default {
  components: {
    SearchInput,
  },
  data() {
    return {
      isLoading: false,
      processing: false,
      isOpenModalDelete: false,
      selectedNote: null,
      dataTableOptions: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          return [
            { title: 'Title', field: 'title', label: 'Title', tdClass: 'center', sortable: true, visible: true, },
            { title: 'Content', field: 'content', label: 'Content', tdClass: 'left', tdComp: 'TdVhtml', sortable: true, visible: true },
            { title: 'ReleasedAt', field: 'released_at', label: 'ReleasedAt', tdClass: 'center', tdComp: 'TdDay', sortable: false, visible: true },
            { title: 'Active', field: 'active', sortable: false, visible: true, tdClass: 'center', tdComp: 'TdBoolean', },
            { title: '', tdComp: Action, visible: 'true', tdClass: 'left', },
          ]
        })(),
        data: [],
        total: 0,
        summary: {},
        query: {
        },
        xprops: {
          eventbus: new Vue()
        }
      },
      newNote: {},
      searchFilter: null,
    }
  },

  watch: {
    'dataTableOptions.query': {
      handler() {
        this.loadData()
      },
      deep: true
    },
  },

  mounted() {
    this.dataTableOptions.xprops.eventbus.$on('openEdit', row => {
      this.$router.push({ name: 'admin.releaseNote.edit', params: { id: row.id }});
    })

    this.dataTableOptions.xprops.eventbus.$on('openDelete', row => {
      this.isOpenModalDelete = true
      this.selectedNote = row
    })
  },

  methods: {
    closeModalDelete() {
      this.isOpenModalDelete = false
    },

    loadData() {
      this.isLoading = true
      this.dataTableOptions.query.search = this.searchFilter

      this.$store.dispatch('releaseNote/all', this.dataTableOptions.query)
        .then((res) => {
          this.dataTableOptions.data = res.data
          this.dataTableOptions.total = res.meta.total
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
        })
    },

    debounceSearch: debounce(function () {
      this.loadData()
    }, 500),

    handleRemoveNote() {
      this.processing = true
      this.$store.dispatch('releaseNote/delete', this.selectedNote.id)
        .then(() => {
          this.closeModalDelete()
          this.loadData()
          this.processing = false
        })
        .catch(() => {
          this.processing = false
        })
    },
  },
}
</script>
<style lang="scss">
.note-page {
  .widget-body {
    padding: 0;
  }

  .nav-item {
    min-width: 120px;
    text-align: center;
  }
}
</style>
