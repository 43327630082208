<template>
  <div class="d-flex align-items-center justify-content-center">
    <button @click.prevent="openEdit" type="button" class="btn btn-icon"><i class="fa fa-edit btn-icon-wrapper"></i></button>
    <button @click.prevent="openDelete" type="button" class="btn btn-icon" title="Delete"><i class="fa fa-trash btn-icon-wrapper"></i></button>
  </div>
</template>
<script>

export default {
  props: ['row', 'nested', 'xprops'],
  methods : {
    openDelete() {
      this.xprops.eventbus.$emit('openDelete', this.row);
    },

    openEdit () {
      this.xprops.eventbus.$emit('openEdit', this.row);
    },
  }
}
</script>

<style scoped lang="scss">
  button {
    i {
      font-size: 1.5rem;
      @media (max-width: 1230px) {
        font-size: 1.3rem;
      }
    }
  }
</style>